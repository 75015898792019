// Bootstrap Framework
import './scss/index.scss';
import jquery from 'jquery';
window.$ = window.jQuery = jquery;
import 'popper.js';
import 'bootstrap';

import "slick-carousel";
import 'jquery-match-height';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import "zoom-vanilla.js";
import "zoom-vanilla.js/dist/zoom.css";

import '@fancyapps/fancybox';

import axios from 'axios';

import Swal from 'sweetalert2';
window.swal = window.Swal = Swal;

$(function () {

    //#region searchbar toggle
    $('.searchbar-toggle').on('click', function (e) {
        e.preventDefault();
        $('.searchbar').toggleClass('show');
    });
    //#endregion

    //#region slider
    $('.slider').slick({
        fade: true,
        nextArrow: '.slider__arrow--next',
        prevArrow: '.slider__arrow--prev',
        autoplay: true,
        autoplaySpeed: 5000,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    adaptiveHeight: true,
                    autoplay: false
                }
            }
        ]
    });
    //#endregion

    //#region carousel
    $('.carousel__slider').slick({
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: false,
        dots: true,
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    });
    //#endregion

    //#region instagram
    const instagram__feed = document.getElementById("instagram__feed");
    if (instagram__feed) {
        const igID = '17841408037451266';
        const igAT = 'EAAagzILnqP0BAM69t79lXgcPnUyhnBOCk5r4iC00EwvDZCVPfeGX81tufHEmP2QdBJnh6k0Rjqo1642oPoTwXzDmd4d0NDOffGG5nmkzuw7juxbIfo00uYxX5G7yoqrxarQND1iacJzThkC8WdFTnp0C3ZBSTlkiS5kNF5ssUnKAA6SYUFQ80ta5tKLmIZD';
        const igFields = 'id,caption,media_type,media_url,permalink,thumbnail_url,username,timestamp';
        const igMediaCount = 4;

        axios.get(`https://graph.facebook.com/${igID}/media?limit=${igMediaCount}&fields=${igFields}&access_token=${igAT}`).then((response) => {
            response.data.data.forEach(element => {
                //console.log(element);
                if (element.media_type == "IMAGE") {
                    let el = $(`<div class="col-lg-3 col-6 [ mb-3 ]">
                                <a target="_blank" href="${element.permalink}">
                                <img src="${element.media_url}"></a>
                                </div>`);

                    $('#instagram__feed').append(el);
                }
                else if (element.media_type == "VIDEO") {
                    let el = $(`<div class="col-lg-3 col-6 [ mb-3 ]">
                                    <video controls style="width:100%">
                                        <source src="${element.media_url}">
                                    </video>
                                </div>`);

                    $('#instagram__feed').append(el);
                }
            });
        }).catch((error) => {
            console.log('Error loading instagram feed.');
            $('#instagram__feed').remove();
        });
    }
    //#endregion

    //#region product gallery
    $('.gallery__zoom').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        asNavFor: '.gallery__thumbs'
    });
    $('.gallery__thumbs').slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        vertical: true,
        verticalSwiping: true,
        slidesToScroll: 1,
        asNavFor: '.gallery__zoom',
        centerMode: false,
        focusOnSelect: true,
        responsive: [
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 3
                }
            }
        ]
    });
    //#enderegion

    //#region tabs mobile
    $('.tabs__mobile select').on('change', function () {
        let tabID = $(this).val();
        $('.tab-pane').removeClass('active');
        $(tabID).addClass('active');
    })
    //#endregion

    //#region navbar mobile
    $('.navbar-toggler').on('click', function (e) {
        e.preventDefault();
        navbarCollapseMobileConfig();
        window.scrollTo(0, 0);
        let navOpened = true;
        if ($('#navbarMain').hasClass('show')) {
            navOpened = false;
        }
        $('.navbar-toggler .hamburger').toggleClass('is-active');
        $('#navbarMain').toggleClass('show');
        let scrollableNav = document.querySelector('#navbarMain');
        if (navOpened) {
            disableBodyScroll(scrollableNav);
        }
        else {
            enableBodyScroll(scrollableNav);
        }
    });
    function navbarCollapseMobileConfig() {
        if ($(window).width() <= 1199) {
            let adH = $('.ad').outerHeight();
            let barH = $('.navbar__mobile').outerHeight();
            $('#navbarMain').css('height', `calc(100vh - ${barH}px - ${adH}px)`);
            $('#navbarMain .dropdown-menu').css('height', `calc(100vh - ${barH}px - ${adH}px)`);
        }
        else {
            $('#navbarMain').css('height', '');
            $('#navbarMain .dropdown-menu').css('height', '');
        }
    }
    $('#navbarMain .dropdown-toggle').on('click', function (e) {
        $('#navbarMain').get(0).scrollTo(0, 0);
        $('#navbarMain').css('overflow', 'hidden');
    });
    //#endregion

    //#region dropdown back mobile
    $('.dropdown-back').on('click', function (e) {
        e.preventDefault();
        $(this).parent().removeClass('show');
        $('#navbarMain').get(0).scrollTo(0, 0);
        $('#navbarMain').css('overflow', '');
    });
    //#endregion

    //#region window resizing trigger
    $(window).on('resize', function () {
        navbarCollapseMobileConfig();
        $('.slider').slick('refresh');
        $('.carousel__slider').slick('refresh');
        $('.gallery__zoom').slick('refresh');
        $('.gallery__thumbs').slick('refresh');
    });
    //#endregion

});